import React, { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import cx from 'classnames';
import moment from 'moment';

import styles from './styles.module.css';
import { Loading, VideoModal } from '../../../../common';
import { NoImage, NotAllowedIcon, PlayVideoIcon } from '../../../../theme/icons';
import { storage } from '../../../../utils';
import { constants } from '../../../../utils/routers/book';
import { getChecked, getExercisesCategories, getSelectOptions } from '../../../../utils/helper';
import { uploadVideoForPatientInfoByHcp } from '../../../../utils/validators';
import {
  useDeleteHospitalVideo,
  useQueryDocumentThumbnailPic,
  useQueryDocumentVideo,
  useSaveHospitalVideo,
} from '../../../../graphql/hospitals';
import { GetState, getCurrenLang, getCurrentFormatDate } from '../../../../redux/selector';
import MediaSettings from '../MediaSettings';
import { SuccessNotifModal, WarnNotifModal } from '../../../../common/NotificationModal';

const MediaForPatient = ({
  index,
  exercisesProcedureFocus,
  focusType1Arr,
  data,
  hospitalNameOptions,
  updateVideoList,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const cancel = t?.common.cancel;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const video_updated = t?.hcp.manage_patient_information?.video_updated;
  const uploading = t?.common.uploading;
  const information_will_deleted = t?.hcp.org_patient_info?.information_will_deleted;
  const sure_delete_patient_info = t?.hcp.org_patient_info?.sure_delete_patient_info;

  // Local state
  const [isSettingsOpen, setOpenSettings] = useState(false);
  const [isVideoModalOpen, setVideoModal] = useState(false);
  const [videoSrcState, setVideoSrcState] = useState<any>('');
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isUploadingToAWS, setIsUploadingToAWS] = useState(false);
  const [isWarningDeleteMediaModalOpen, setWarningDeleteMediaModal] = useState(false);

  const fromLS = storage.get('user');
  const role = fromLS && fromLS.role;
  const isAdmin = role === constants.admin;
  const formatsDate = useSelector((state: GetState) => getCurrentFormatDate(state));

  // Endpoints
  // get photo picture from AWS by Uuid
  const { _getDocumentThumbnailPic, thumbnailPic } = useQueryDocumentThumbnailPic();
  // get video from AWS by uuid
  const { _getDocumentVideoByUuid, videoSrc, errorVideo } = useQueryDocumentVideo();
  // Delete Hospital video
  const {
    _deleteHospitalVideo,
    videoDeleted,
    deleteVideoError,
    deleteVideoLoading,
  } = useDeleteHospitalVideo();
  const {
    _saveHospitalVideo,
    errorSaveHospitalVideo,
    loadingSaveHospitalVideo,
    hospitalVideoSaved,
  } = useSaveHospitalVideo();

  // Get thumbnail source link from DB
  useEffect(() => {
    if (data.thumbnailUuid) {
      _getDocumentThumbnailPic({
        variables: {
          thumbnailUuid: data.thumbnailUuid,
        },
      });
    } else {
      setIsUploadingToAWS(() => true);
    }
  }, [data.thumbnailUuid]);

  useEffect(() => {
    if (videoSrc) {
      setVideoSrcState(videoSrc);
      setVideoModal(() => !isVideoModalOpen);
    }
  }, [videoSrc]);

  // If get video from AWS error show message
  useEffect(() => {
    if (errorVideo) {
      toast.error(errorVideo);
    }
  }, [errorVideo]);

  const focusByprocedures: any = {};
  focusType1Arr.map((procedure: any, i: number) => {
    focusByprocedures[`procedure${i + 1}`] = Boolean(
      data.procedureTypes?.length &&
        getChecked(exercisesProcedureFocus[`procedure${i + 1}`].name, data.procedureTypes),
    );
    return null;
  });

  const { hcpWhoUpdated, lastChanged } = data;
  const proceduresList = data.procedureTypes?.length
    ? data.procedureTypes?.map((procedure: { name: string }) => ` ${procedure.name}`).toString()
    : '';

  const initialValues = {
    id: data.id,
    name: data.name,
    description: data.description || '',
    videoUuid: data.videoUuid,
    thumbnailUuid: data.thumbnailUuid,
    isAllowDownloadVideos: data.isAllowDownloadVideos,
    organisationName: data.hospital ? getSelectOptions([data.hospital]) : null,
    ...focusByprocedures,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => uploadVideoForPatientInfoByHcp(t, isAdmin, true),
    // validate: (values) => validationProcedure(values, focusType1Arr, t),
    onSubmit: async (values: any) => {
      const request = {
        videoProcedureTypesId: getExercisesCategories(values, exercisesProcedureFocus, 'procedure'),
        videoData: {
          id: values.id,
          name: values.name.trim(),
          description: values.description ? values.description.trim() : null,
          isAllowDownloadVideos: values.isAllowDownloadVideos,
        },
      };

      _saveHospitalVideo(request);
    },
  });

  const { values } = formik;

  // If save video error show message
  useEffect(() => {
    if (errorSaveHospitalVideo) {
      toast.error(errorSaveHospitalVideo);
    }
  }, [errorSaveHospitalVideo]);

  // // If save video success show message
  useEffect(() => {
    if (hospitalVideoSaved) {
      setSuccessModal(() => true);
    }
  }, [hospitalVideoSaved]);

  // If error delete Hospital Videos show message
  useEffect(() => {
    if (videoDeleted) {
      setWarningDeleteMediaModal(() => false);
      updateVideoList();
    }
  }, [videoDeleted]);

  // If error delete Hospital Video show message
  useEffect(() => {
    if (deleteVideoError) {
      toast.error(deleteVideoError);
    }
  }, [deleteVideoError]);

  // Get Video link from AWS
  const showMedia = async (): Promise<void> => {
    await _getDocumentVideoByUuid({
      variables: {
        videoKey: data.videoUuid,
      },
    });
  };

  // Clouses open modal windows
  const onclose = (): void => {
    setVideoModal(() => false);
  };

  // Cancel changes and clouse settings
  const onCancel = (): void => {
    formik.resetForm();
    setOpenSettings(() => false);
  };

  // Clouse success modal window
  const oncloseModal = (): void => {
    setOpenSettings(() => false);
    setSuccessModal(() => false);
    updateVideoList();
  };

  // Delete Patient Info
  const onDeleteVideo = (): void => {
    _deleteHospitalVideo([data.id]);
  };

  // JSX
  const loadingJSX = (loadingSaveHospitalVideo || deleteVideoLoading) && <Loading />;
  const setPlayVideoIcon = (videoUuid: string): JSX.Element => {
    if (videoUuid) {
      return <PlayVideoIcon className={styles['start--btn']} />;
    }
    return <img src={NotAllowedIcon} alt="No video" className={styles['btn--not-allowed']} />;
  };

  const setThumbnailJsx = (thumbLink: string): JSX.Element => {
    if (thumbLink) {
      return (
        <img
          src={`data:image/jpeg;charset=utf-8;base64,${thumbnailPic}`}
          alt="Thumbnail"
          crossOrigin="anonymous"
        />
      );
    }
    return <img src={NoImage} alt="Thumbnail" />;
  };

  const warnDelPIContentJsx = (
    <>
      <div className={styles['video-exercise__modal-description']}>{information_will_deleted}</div>
      <div className={styles['video-exercise__modal-description']}>{sure_delete_patient_info}</div>
    </>
  );

  return (
    <div className={styles['video-exercise__item']}>
      <div className={styles['video-exercise__row']}>
        {loadingJSX}

        {/* Thumbnail */}
        <div className={styles['video-exercise__block']}>
          {!isUploadingToAWS && (
            <div
              className={cx({
                [styles.preview]: true,
                [styles['preview-not-allowed']]: !values.videoUuid,
              })}
              role="presentation"
              onClick={values.videoUuid && showMedia}
            >
              {setThumbnailJsx(thumbnailPic)}
              {setPlayVideoIcon(values.videoUuid)}
            </div>
          )}
          {isUploadingToAWS && <div className={styles.isUploading}>{uploading}...</div>}
        </div>

        {/* Name and Description */}
        <div className={styles['video-exercise__name-container']}>
          <div className={styles['video-exercise__name']}>{data.name}</div>
          <div className={styles['video-exercise__description']}>{data.description}</div>
          {/* Sm screen */}
          <div className={styles['video-exercise__sm-screen']}>
            <div className={styles['video-exercise__description']}>{proceduresList}</div>
          </div>
          <div className={styles['video-exercise__sm-screen']}>
            <div className={styles['video-exercise__description']}>
              <div
                className={cx({
                  [styles['video-exercise__btn--settings']]: true,
                  [styles['video-exercise__btn--settings-open']]: isSettingsOpen,
                  // [styles['video-exercise__btn--disabled']],
                })}
                aria-hidden
                onClick={(): void => setOpenSettings(() => !isSettingsOpen)}
              >
                {settings_t}
              </div>
            </div>
          </div>
        </div>

        {/* Setting last changed */}
        <div className={styles['video-exercise__date-container']}>
          {lastChanged && (
            <>
              <div className={styles['video-exercise__date']}>
                {moment(Number(lastChanged)).format(formatsDate.momentFormat)}
              </div>
              <div className={styles['video-exercise__date-author']}>{hcpWhoUpdated}</div>
            </>
          )}
        </div>

        {/* Focus bg scgreen */}
        <div className={styles['video-exercise__focus']}>{proceduresList}</div>

        {/* Action */}
        <div className={styles['video-exercise__action']}>
          <div
            className={cx({
              [styles['video-exercise__btn--settings']]: true,
              [styles['video-exercise__btn--settings-open']]: isSettingsOpen,
              // [styles['video-exercise__btn--disabled']],
            })}
            aria-hidden
            onClick={(): void => setOpenSettings(() => !isSettingsOpen)}
          >
            {settings_t}
          </div>
        </div>
      </div>
      {isSettingsOpen && (
        <MediaSettings
          index={index}
          formik={formik}
          focusType1Arr={focusType1Arr}
          exercisesProcedureFocus={exercisesProcedureFocus}
          isAdmin={isAdmin}
          hospitalNameOptions={hospitalNameOptions}
          onCheckIncomingFile={(): null => null}
          fileInputKey={Date.now()}
          onCancel={onCancel}
          loadingJSX={loadingJSX}
          isEdit
          setWarningDeleteMediaModal={setWarningDeleteMediaModal}
        />
      )}

      {/* Popups */}
      {isVideoModalOpen && <VideoModal videoSrc={videoSrcState} onclose={onclose} />}

      {/* Warning befor delete media content */}
      {isWarningDeleteMediaModalOpen && (
        <WarnNotifModal
          onClose={(): void => setWarningDeleteMediaModal(false)}
          content={warnDelPIContentJsx}
          cancelBtnName={cancel}
          actionBtnName={ok}
          actionMethod={onDeleteVideo}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessNotifModal onClose={oncloseModal} description={video_updated} btnName={ok} />
      )}
    </div>
  );
};

export default MediaForPatient;
