import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

import overviewStyles from '../styles.module.css';
import style from './style.module.css';
import { WoundImagesIcon } from '../../../theme/icons';
import { WoundImagesCardProps } from '../types';
import { getCurrenLang } from '../../../redux/selector';
import { CardTitle, EndMessage, WoundMedia } from '../Elements';
import { spinner } from '../../../common/Loader';
import { MEDIA_PER_PAGE } from '../../../utils/variables';
import { useQueryGetPatientDocument } from '../../../graphql/attachments';
import { WoundImageType } from '../../../pages/Patient/WoundImages/types';
import { initialPage } from '../../../utils/helper';

const WoundImagesCard = ({ patientId, loading }: WoundImagesCardProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const loading_t = t?.header.loading;
  const noMoreMedia = t?.dashboard.hcp.profile_patient.noMoreMedia;
  const wound_images = t?.menu.wound_images;
  const not_send_you_wound = t?.dashboard.hcp.profile_patient.not_send_you_wound;

  // Endpoints
  // Get patients documents from DB
  const {
    _getPatientDoc,
    loadingDoc,
    errorDoc,
    patientDocuments,
    data,
    totalPages,
    totalItems,
  } = useQueryGetPatientDocument();

  // Local state
  const [patientsMedia, setPatientsMedia] = useState<any>(null);
  const [itemsPerPageCumulative, setItemsPerPageCumulative] = useState(MEDIA_PER_PAGE);

  const endMessage = totalPages === 1;

  const getPatientDocuments = (itemsPerPage: number, page: number): void => {
    _getPatientDoc({
      variables: {
        patientId,
        searchProps: { searchField: 'documentDate', searchValue: '' },
        filterMedia: [],
        listProps: {
          itemsPerPage,
          page,
          sortBy: 'documentDate',
          sortDirection: 'ASC',
        },
      },
    });
  };

  // Send request to the database
  useEffect(() => {
    if (patientId) {
      getPatientDocuments(MEDIA_PER_PAGE, initialPage);
    }
  }, [patientId]);

  // After receiving the list of patients info from the database, display them on the UI
  useEffect(() => {
    if (patientDocuments && !loadingDoc) {
      setPatientsMedia(patientDocuments.list);
    } else if (!loadingDoc) {
      setPatientsMedia(null);
    }
  }, [data]);

  // Show message if loading documents from patients is error
  useEffect(() => {
    if (errorDoc) {
      toast.error(errorDoc);
    }
  }, [errorDoc]);

  // Get next page from DB
  const setNextPage = (): void => {
    const nextNumberItemsPerPage = itemsPerPageCumulative + MEDIA_PER_PAGE;
    setItemsPerPageCumulative(nextNumberItemsPerPage);
    getPatientDocuments(nextNumberItemsPerPage, initialPage);
  };

  // JSX
  const loadingJSX = (loading || loadingDoc) && spinner;

  const noData = patientsMedia?.length === 0 && (
    <div className={overviewStyles.nodata}>{not_send_you_wound}</div>
  );

  const endMsgJsx = (
    <EndMessage totalItems={totalItems} ITEMS_PER_PAGE={MEDIA_PER_PAGE} description={noMoreMedia} />
  );

  return (
    <div className={overviewStyles['overview__sm-card-container']}>
      {loadingJSX}
      <CardTitle icon={<WoundImagesIcon />} title={wound_images} />

      <div className={style.notifications__container}>
        {/* List */}
        {patientsMedia && !noData ? (
          <InfiniteScroll
            dataLength={patientsMedia.length}
            next={(): any => setNextPage()}
            hasMore={!endMessage}
            loader={<div className={style.notifications__loader}>{`${loading_t || ''}...`}</div>}
            scrollableTarget="scrollableWoundImagesDiv"
            endMessage={endMsgJsx}
          >
            {patientsMedia.map((fromPatientItem: WoundImageType, index: number) => (
              <WoundMedia key={`${fromPatientItem.name}${String(index)}`} data={fromPatientItem} />
            ))}
          </InfiniteScroll>
        ) : (
          <></>
        )}
        {noData}
      </div>
    </div>
  );
};

export default WoundImagesCard;
